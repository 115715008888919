.contents {
    display: grid;
    justify-content: center;
    background: var(--tg-theme-bg-color);
    padding-bottom: 3em;
}

.info {
    padding: .5em 1.5em;
}

.title {
    color: var(--tg-theme-text-color);
    margin-bottom: .5em;
}

.link {
    color: var(--tg-theme-link-color);
    text-decoration: none;
}

.link:hover {
    cursor: pointer;
}

.price {
    color: var(--tg-theme-hint-color);
    margin: 0;
}

.contents > img {
    max-width: 100%;
    height: auto;
}

.description_label {
    color: var(--tg-theme-text-color);
    margin-bottom: 0;
}

.description {
    margin-top: .5em;
    color: var(--tg-theme-text-color);
}

.actions_container {
    left: 0;
    bottom: 0;
    position: fixed;
    width: 100vw;
    background: var(--tg-theme-bg-color);
}

.actions_container > .wrapper {
    display: flex;
    justify-content: center;
}

.addToCartButton {
    border: 0;
    border-radius: .25em;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    margin-top: 1em;
    padding: 1em 1.5em;
}

.actions {
    display: flex;
    justify-content: center;
    gap: .5em;
    align-items: center;
}

.actions > .label {
    color: var(--tg-theme-text-color);
    background: transparent;
    border: 0;
    border-bottom: 1px solid var(--tg-theme-hint-color);
    margin: 0;
    width: 4em;
    text-align: center;
}

.actions > button {
    border: 0;
    border-radius: .25em;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    padding: .75em .85em;
}
