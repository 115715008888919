.card {

}

.container {
    display: grid;
    grid-template-columns: 3fr 5fr 1fr;
    grid-column-gap: .5em;
}

.container > img {
    max-width: 100%;
    height: auto;
}

.name {
    color: var(--tg-theme-text-color);
    font-weight: bold;
}

.link {
    color: var(--tg-theme-link-color);
}

.link:hover {
    cursor: pointer;
}

.removeItem {
    background: transparent;
    border: 0;
}

.removeItem > svg {
    max-width: 100%;
    height: auto;
}

.price {
    color: var(--tg-theme-hint-color);
    margin: 0;
}

.actionBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5em 0;
}

.actions {
    display: flex;
    justify-content: center;
    gap: .5em;
}

.actions > .label {
    color: var(--tg-theme-text-color);
}

.actions > button {
    border: 0;
    border-radius: .25em;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    padding: 0 .6em;
}
