.contents {
    padding: .5em 1.5em 3em;
}

.title {
    color: var(--tg-theme-text-color);
    margin-top: 0;
}

.summary {
    display: flex;
    justify-content: space-around;
    align-items: center;

    background: var(--tg-theme-bg-color);
    background-image: linear-gradient(rgb(255 255 255/5%) 0 0);

    padding: 1em 0;
}

.summary > div > .label {
    color: var(--tg-theme-text-color);
}

.summary > div > .value {
    color: var(--tg-theme-hint-color);
}

.stickToBottom {
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
}
