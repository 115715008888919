.card {
    display: grid;
    grid-template-rows: 1fr auto;
    justify-items: center;

    background: var(--tg-theme-bg-color);
    background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
    padding: .5em 1em;
    border-radius: .25em;
}

.card.active {
    background: var(--tg-theme-button-color);
}

.card > img {
    max-width: 100%;
    max-height: 70%;
    width: auto;
}

.card > p {
    margin: 1em 0 0;
    text-align: center;

    color: var(--tg-theme-text-color);
}

.card.active > p {
    color: var(--tg-theme-button-text-color);
}
