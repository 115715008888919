.card {
    display: grid;
    justify-content: center;
    background: var(--tg-theme-bg-color);
    background-image: linear-gradient(rgb(0 0 0/5%) 0 0);
    padding: .5em 1em;
    border-radius: .25em;
}

.title {
    text-align: center;
    color: var(--tg-theme-text-color);
}

.meta {
    display: flex;
    justify-content: space-between;
}

.link {
    color: var(--tg-theme-link-color);
    text-decoration: none;
}

.link:hover {
    cursor: pointer;
}

.price {
    color: var(--tg-theme-hint-color);
    margin: 0;
}

.card > img {
    max-width: 100%;
    height: auto;
}

.addToCartButton {
    border: 0;
    border-radius: .25em;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    margin-top: 1em;
    padding: .75em .5em;
}

.actions {
    display: flex;
    justify-content: center;
    gap: .5em;
    margin-top: .85em;
    align-items: center;
}

.actions > .label {
    color: var(--tg-theme-text-color);
    margin: 0;
}

.actions > button {
    border: 0;
    border-radius: .25em;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    padding: .75em .85em;
}
