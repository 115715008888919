.emptyContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: var(--tg-theme-text-color);
    text-align: center;
}
