.contents {
    display: grid;
    grid-auto-columns: 30vw;
    grid-auto-flow: column;
    grid-column-gap: .5em;

    background: var(--tg-theme-bg-color);
    padding: .25em;

    overflow-x: scroll;
    scrollbar-height: 1em;
}

.contents::-webkit-scrollbar {
    height: 1em;
}
