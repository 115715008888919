.contents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .5em;
    background: var(--tg-theme-bg-color);
    padding: .25em;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25vh;
}

.noContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25vh;

    padding: 0 2em;
}

.noContent > h3 {
    color: var(--tg-theme-text-color);
    text-align: center;
}
