.contents {
    position: relative;
}

.label {
    display: block;
    color: rgba(93, 111, 125);
    transition: all 250ms ease-in-out;
    font-weight: 500;
    font-size: .8em;
    position: absolute;
}

.label:hover {
    cursor: pointer;
}

.input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #5D6F7D99;
    color: var(--tg-theme-text-color);
    padding: .25em 0 !important;
    margin-top: 1.25em;
    width: 100%;
}

.label:has(+ .input:not(:focus):placeholder-shown) {
    transform: translate(0, 1em);
    font-size: .95em;
}

.label:has(+ .input:focus) {
    color: var(--tg-theme-button-color);
}

.input:focus {
    border-bottom: 2px solid var(--tg-theme-button-color);
    outline: none !important;
}

.input::placeholder {
    visibility: hidden !important;
}

.label.error {
    color: #ef5852 !important;
}

.input.error {
    border-bottom: 2px solid #ef5852 !important;
}
